const addresses = [
  {
    address: ['CodeMyMobile', '20 Smith Dr.', 'Allentown, NJ 08501', 'USA'],
    phone: '+91-7840868988',
    email: 'info@codemymobile.com',
  },
  {
    address: [
      'CodeMyMobile',
      '2nd Floor, P.R Plaza, Plot No C 109',
      'Lalapura Road, Vaishali Nagar',
      'Jaipur, Rajasthan 302021, India',
    ],
    phone: '+91-7840868988',
    email: 'info@codemymobile.com',
  },
];

export default addresses;
